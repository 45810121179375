import type { LinksFunction, MetaFunction } from "@remix-run/node"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import ContentLoader from "~/components/content-loader"
import Footer from "~/components/layout/footer"
import Header from "~/components/layout/header"
import { ScrollArea, ScrollBar } from "~/components/ui/scroll-area"
import useAppContext from "~/hooks/use-app-context"
import { Companion } from "~/types"
import getCompanions from "~/queries/get-companions"
import { CompanionCoverCard } from "~/components/companion-cover-card"
import CompanionSearchDialog from "~/components/companion-search-dialog"
import { useMemo } from "react"

export const meta: MetaFunction = () => {
  return [
    { title: "Fanmade - create your perfect AI companion" },
    { name: "description", content: "Real talk - Privately yours" },
  ]
}

export const links: LinksFunction = () => [
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200&icon_names=female,male,transgender",
  },
]

export default function Index() {
  const { supabase } = useAppContext()

  const { data: companions, isPending } = useQuery({
    queryKey: ["companions"],
    queryFn: async () => getCompanions(supabase),
  })

  const popularCompanions = useMemo(
    () =>
      companions
        ?.filter((p) => p.chats && p.chats.count !== null)
        ?.sort((a, b) => b.chats.count - a.chats.count)
        ?.slice(0, 8),
    [companions]
  )

  return (
    <>
      <Header goBack={false}>
        <div className="flex items-center w-full gap-8">
          <h1 className="text-xl font-bold">Explore</h1>
          <CompanionSearchDialog />
        </div>
      </Header>

      <ContentLoader loading={isPending}>
        <div className="flex flex-col gap-12">
          <section className="flex flex-col gap-5">
            <h2 className="text-xl font-semibold">Most popular</h2>
            <ScrollArea className="w-full whitespace-nowrap">
              <div className="flex space-x-4 pb-4">
                {popularCompanions?.map((companion: Companion) => (
                  <CompanionCoverCard
                    className="max-w-[300px]"
                    key={companion.id}
                    companion={companion}
                  />
                ))}
              </div>
              <ScrollBar orientation="horizontal" />
            </ScrollArea>
          </section>

          <section className="flex flex-col gap-5 w-full">
            <h2 className="text-xl font-semibold">Explore</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
              {companions?.map((companion: Companion) => (
                <CompanionCoverCard key={companion.id} companion={companion} />
              ))}
            </div>
          </section>
        </div>
      </ContentLoader>
      <Footer />
    </>
  )
}
