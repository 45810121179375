import { Dialog } from "@radix-ui/react-dialog"
import { keepPreviousData, useQuery } from "@tanstack/react-query"
import { useState } from "react"
import useAppContext from "~/hooks/use-app-context"
import { DialogContent, DialogTitle, DialogTrigger } from "./ui/dialog"
import { Search } from "lucide-react"
import { Input } from "./ui/input"
import { genders } from "~/lib/constants/companion"
import { Button } from "./ui/button"
import { ScrollArea } from "./ui/scroll-area"
import { Link } from "react-router-dom"
import getProfileCover from "~/routes/companions/get-profile-cover"
import { srcset } from "~/lib/utils"

interface CompanionFilter {
  gender?: "male" | "female" | "transgender"
  name?: string
}

export default function CompanionSearchDialog() {
  const { supabase } = useAppContext()
  const [filter, setFilter] = useState<CompanionFilter>({})

  const filterCompanions = async () => {
    let query = supabase
      .from("companions")
      .select("*, profile_covers:images(url)")
      .is("user_id", null)
      .filter("profile_covers.is_profile_cover", "eq", true)

    if (filter?.gender) {
      query = query.eq("gender", filter.gender)
    }

    if (filter?.name) {
      const namePattern = `%${filter.name}%`
      query = query.or(
        `first_name.ilike.${namePattern},last_name.ilike.${namePattern}`
      )
    }

    const { data, error } = await query
    if (error) throw error
    return data
  }

  const { data: companions } = useQuery({
    queryKey: ["filterCompanions", filter],
    queryFn: filterCompanions,
    placeholderData: keepPreviousData,
  })

  return (
    <Dialog>
      <DialogTrigger>
        <div className="relative">
          <Search className="w-4 h-4 absolute inset-2.5" />
          <Input className="pl-8" placeholder="Search companions" />
        </div>
      </DialogTrigger>
      <DialogContent className="p-0 m-0 h-[70vh] max-w-screen-md flex flex-col pb-4">
        <DialogTitle className="border-b py-1">
          <Input
            placeholder="search companions..."
            className="naked-input"
            onChange={(e) => {
              setFilter({ ...filter, name: e.target.value })
            }}
          />
        </DialogTitle>
        <div className="flex gap-2 items-center px-4">
          <div className="flex gap-2 py-2">
            {genders.map((gender, i) => (
              <Button
                key={i}
                variant={filter.gender === gender ? "default" : "secondary"}
                onClick={() => setFilter({ gender })}
              >
                {gender}
                <span className="material-symbols-outlined ml-1">{gender}</span>
              </Button>
            ))}
          </div>
          <div className="flex gap-2 py-2">
            <Button variant="secondary">Realistic</Button>
            <Button variant="secondary">Fantasy</Button>
          </div>
        </div>
        {companions && companions.length > 0 ? (
          <ScrollArea className="overflow-y-auto flex-1 px-4">
            <div className="grid grid-cols-2 sm:grid-cols-4 gap-2">
              {companions.map((companion, i) => (
                <div
                  key={i}
                  className="overflow-hidden rounded-lg relative shrink-0"
                >
                  <Link to={`/companions/${companion.uuid}/chat`}>
                    <img
                      className="aspect-[3/4] object-cover"
                      src={getProfileCover(companion)}
                      alt={`${companion.first_name} ${companion.last_name}`}
                      srcSet={srcset(getProfileCover(companion))}
                      sizes="(max-width: 1024px) 100vw, 50vw"
                    />

                    <div className="absolute bottom-0 right-0 w-full px-2 py-4">
                      <h4 className="font-bold text-lg text-white">
                        {companion.first_name} {companion.last_name}
                      </h4>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </ScrollArea>
        ) : (
          <div className="flex justify-center items-center h-[50%] px-4 text-muted-foreground text-sm">
            No results found{" "}
            <Button onClick={() => setFilter({})} variant="link">
              reset filter
            </Button>
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}
