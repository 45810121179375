import { SupabaseClient } from "@supabase/supabase-js"
import { Companion } from "~/types"

export default async function getCompanions(supabase: SupabaseClient) {
  const { data, error } = await supabase
    .from("companions")
    .select("*, profile_covers:images(url), chats:companion_chat_counts(count)")
    .filter("profile_covers.is_profile_cover", "eq", true)
    .is("user_id", null)
    .returns<Companion[]>()
  if (error) {
    throw error
  }
  return data
}
