import { Link } from "@remix-run/react"

export default function Footer() {
  return (
    <footer className="text-muted-foreground border-t border-b">
      <div className="container mx-auto px-6 py-12">
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5">
          <div className="sm:col-span-2 flex flex-col gap-3 justify-between">
            <Logo />
            <div className="text-sm">
              © 2024{" "}
              <Link to="/" className="hover:underline">
                Fanmade
              </Link>
              . All Rights Reserved.
            </div>
          </div>
          <div>
            <h2 className="mb-6 text-sm font-semibold uppercase text-foreground">
              Legal
            </h2>
            <ul className="text-sm">
              <li className="mb-4">
                <Link to="/policies" className="hover:underline">
                  Privacy Policy
                </Link>
              </li>
              <li className="mb-4">
                <Link to="/policies" className="hover:underline">
                  Underage policy
                </Link>
              </li>
              <li className="mb-4">
                <Link to="/policies" className="hover:underline">
                  Cookie policy
                </Link>
              </li>
              <li className="mb-4">
                <Link to="/policies" className="hover:underline">
                  Terms &amp; Conditions
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h2 className="mb-6 text-sm font-semibold uppercase text-foreground">
              Sitemap
            </h2>
            <ul className="text-sm">
              <li className="mb-4">
                <Link to="#" className="hover:underline">
                  create AI girlfriend
                </Link>
              </li>
              <li className="mb-4">
                <Link to="#" className="hover:underline">
                  create AI boyfriend
                </Link>
              </li>
              <li className="mb-4">
                <Link to="#" className="hover:underline">
                  create AI gamer friend
                </Link>
              </li>
              <li className="mb-4">
                <Link to="#" className="hover:underline">
                  FAQ
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h2 className="mb-6 text-sm font-semibold uppercase text-foreground">
              Social Media
            </h2>
            <ul className="text-sm">
              <li className="mb-4">
                <Link
                  rel="noreferrer"
                  target="_blank"
                  to="https://discord.gg/m8777RtNwH"
                  className="hover:underline"
                >
                  Discord
                </Link>
              </li>
              <li className="mb-4">
                <Link to="#" className="hover:underline">
                  X
                </Link>
              </li>
              <li className="mb-4">
                <Link to="#" className="hover:underline">
                  Instagram
                </Link>
              </li>
              <li className="mb-4">
                <Link to="#" className="hover:underline">
                  TikTok
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}

function Logo() {
  return (
    <div className="flex gap-2">
      <div className="flex brand aspect-square size-8 items-center justify-center rounded-lg bg-primary text-white text-xl">
        <span className="material-symbols-sharp">person_play</span>
      </div>
      <div className="flex flex-col gap-0.5 leading-none">
        <span className="font-semibold brand text-2xl text-primary">
          Fanmade
        </span>
      </div>
    </div>
  )
}
